import React from "react"

const Logo = ({ width = "237", className = "", height = "49", viewBox = "0 0 239 49" }) =>
      <svg width={width}
           height={height}
           viewBox={viewBox}
           className={className ? className : "svg-icon"}
           xmlns="http://www.w3.org/2000/svg"
           xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
          <linearGradient id="a" x1="0.5" y1="0.953" x2="0.5" y2="0.097" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#949494"/>
            <stop offset="1" stopColor="#e1e1e1"/>
          </linearGradient>
          <linearGradient id="b" x1="0.5" y1="1.012" x2="0.5" y2="0.122" gradientUnits="objectBoundingBox">
            <stop offset="0.038" stopColor="#b79139"/>
            <stop offset="0.995" stopColor="#eacf88"/>
          </linearGradient>
          <linearGradient id="c" x1="0.502" y1="1.021" x2="0.502" y2="0.039" xlinkHref="#b"/>
          <linearGradient id="d" x1="0.498" y1="1.021" x2="0.498" y2="0.039" xlinkHref="#b"/>
          <linearGradient id="e" x1="0.5" y1="1.042" x2="0.5" y2="0.02" xlinkHref="#b"/>
          <linearGradient id="f" x1="0.504" y1="1.042" x2="0.504" y2="0.02" xlinkHref="#b"/>
          <linearGradient id="g" x1="0.502" y1="1.037" x2="0.502" y2="0.02" xlinkHref="#b"/>
          <linearGradient id="h" x1="0.5" y1="0.983" x2="0.5" y2="0.081" xlinkHref="#b"/>
          <linearGradient id="i" x1="0.489" y1="1.021" x2="0.489" y2="0.044" xlinkHref="#b"/>
          <linearGradient id="j" x1="0.497" y1="1.042" x2="0.497" y2="0.02" xlinkHref="#b"/>
          <linearGradient id="k" x1="0.501" y1="1.021" x2="0.501" y2="0.039" xlinkHref="#b"/>
        </defs>
        <g transform="translate(23.013 7.612)">
          <path d="M43.7,8.6l-4.691,4.691A16.832,16.832,0,0,1,29.629,41L26,47.9h.443A23.427,23.427,0,0,0,43.7,8.6Z"
                transform="translate(-26 -8.6)" fill="url(#a)"/>
        </g>
        <path d="M13.631,25.226h.443a1.371,1.371,0,0,1,.177-.708c.177-.089.266-.177.443-.177H26.377L18.322,39.388A16.6,16.6,0,0,1,6.638,23.456,16.81,16.81,0,0,1,23.456,6.638a16.31,16.31,0,0,1,9.825,3.186l4.78-4.691A23.451,23.451,0,1,0,15.136,45.407a25.751,25.751,0,0,0,5.134,1.328l3.363-6.461L32.13,23.9v-3.54h-18.5Z"
              fill="url(#b)"/>
        <g transform="translate(60.011 9.825)">
          <path d="M77.625,20.774h7.169v.531a13.088,13.088,0,0,1-.443,3.452,7.315,7.315,0,0,1-1.5,2.655,7.7,7.7,0,0,1-6.2,2.744A8.916,8.916,0,0,1,67.8,21.128a8.551,8.551,0,0,1,2.655-6.373A8.748,8.748,0,0,1,76.917,12.1a9.167,9.167,0,0,1,3.806.8,10.215,10.215,0,0,1,3.363,2.744l-1.859,1.77a6.45,6.45,0,0,0-5.222-2.832,6.2,6.2,0,0,0-4.6,1.859,6.406,6.406,0,0,0-1.859,4.691,6.061,6.061,0,0,0,2.124,4.78,6.448,6.448,0,0,0,4.249,1.77,4.962,4.962,0,0,0,3.452-1.328,4.405,4.405,0,0,0,1.682-3.186H77.713v-2.39Z"
                transform="translate(-67.8 -11.215)" fill="url(#c)"/>
          <path d="M91.9,21.04a8.5,8.5,0,0,1,2.655-6.284,9.073,9.073,0,0,1,15.49,6.373A8.691,8.691,0,0,1,107.39,27.5a8.89,8.89,0,0,1-6.461,2.655,9.144,9.144,0,0,1-6.019-2.3A8.625,8.625,0,0,1,91.9,21.04Zm2.567,0a6.21,6.21,0,0,0,1.947,4.78,6.091,6.091,0,0,0,4.426,1.859,6.2,6.2,0,0,0,4.6-1.859,6.406,6.406,0,0,0,1.859-4.691,6.55,6.55,0,0,0-1.859-4.691,6.2,6.2,0,0,0-4.6-1.859,6.346,6.346,0,0,0-4.6,1.859A6.646,6.646,0,0,0,94.467,21.04Z"
                transform="translate(-70.569 -11.215)" fill="url(#d)"/>
          <path d="M120.767,12.5V27.37H125.9v2.478h-7.7V12.5Z" transform="translate(-73.59 -11.261)" fill="url(#e)"/>
          <path d="M131.8,29.848V12.5h3.629a13.2,13.2,0,0,1,4.16.531,8.112,8.112,0,0,1,2.921,1.682,8.344,8.344,0,0,1,2.655,6.461,8.189,8.189,0,0,1-2.832,6.461,8.286,8.286,0,0,1-2.921,1.682,12.606,12.606,0,0,1-4.072.443H131.8Zm2.655-2.478h1.151a9.42,9.42,0,0,0,2.921-.354,6.388,6.388,0,0,0,2.124-1.239,5.834,5.834,0,0,0,1.947-4.6,5.713,5.713,0,0,0-1.947-4.6,7.188,7.188,0,0,0-5.045-1.593h-1.151Z"
                transform="translate(-75.152 -11.261)" fill="url(#f)"/>
          <path d="M162.359,14.978h-6.992v4.16h6.727v2.478h-6.727v5.842h6.992v2.478H152.8V12.5h9.559Z"
                transform="translate(-77.565 -11.261)" fill="url(#g)"/>
          <path d="M169.6,29.688V11.1l12.657,13.277V12.339h2.655V30.75L172.255,17.561V29.688Z"
                transform="translate(-79.495 -11.1)" fill="url(#h)"/>
          <path d="M213.533,15.009l-2.124,1.239a2.94,2.94,0,0,0-1.151-1.328,2.91,2.91,0,0,0-3.186.266,2.026,2.026,0,0,0-.708,1.5c0,.885.62,1.5,1.859,2.036l1.77.708a6.423,6.423,0,0,1,3.1,2.124,5.093,5.093,0,0,1,.974,3.009,5.445,5.445,0,0,1-5.576,5.576,5.314,5.314,0,0,1-3.717-1.328A5.724,5.724,0,0,1,203,25.011l2.655-.62a4.659,4.659,0,0,0,.62,2.124,2.858,2.858,0,0,0,2.39,1.151,2.8,2.8,0,0,0,2.036-.8,2.871,2.871,0,0,0,.8-2.124,2.619,2.619,0,0,0-.177-.885,3.588,3.588,0,0,0-.443-.8,3.214,3.214,0,0,0-.8-.708c-.354-.177-.708-.443-1.151-.62l-1.682-.708c-2.39-.974-3.54-2.478-3.54-4.426a4.249,4.249,0,0,1,1.5-3.275A5.315,5.315,0,0,1,208.93,12,5.425,5.425,0,0,1,213.533,15.009Z"
                transform="translate(-83.332 -11.203)" fill="url(#i)"/>
          <path d="M223.667,12.5V27.37H228.8v2.478h-7.7V12.5Z" transform="translate(-85.411 -11.261)" fill="url(#j)"/>
          <path d="M232.9,21.04a8.5,8.5,0,0,1,2.655-6.284,9.073,9.073,0,0,1,15.49,6.373A8.691,8.691,0,0,1,248.39,27.5a8.89,8.89,0,0,1-6.461,2.655,9.144,9.144,0,0,1-6.019-2.3A8.625,8.625,0,0,1,232.9,21.04Zm2.655,0a6.21,6.21,0,0,0,1.947,4.78,6.091,6.091,0,0,0,4.426,1.859,6.2,6.2,0,0,0,4.6-1.859,6.406,6.406,0,0,0,1.859-4.691,6.55,6.55,0,0,0-1.859-4.691,6.2,6.2,0,0,0-4.6-1.859,6.346,6.346,0,0,0-4.6,1.859A6.646,6.646,0,0,0,235.555,21.04Z"
                transform="translate(-86.766 -11.215)" fill="url(#k)"/>
          <path d="M263.138,14.978v14.87h-2.655V14.978H256.5V12.5h10.621v2.478Z" transform="translate(-89.478 -11.261)"
                fill="url(#j)"/>
        </g>
        <g transform="translate(60.454 33.192)">
          <path d="M68.3,41.1a3.017,3.017,0,0,1,.974-2.213A3.073,3.073,0,0,1,71.486,38,3.13,3.13,0,0,1,73.7,43.4a3.135,3.135,0,0,1-2.3.885,3.172,3.172,0,0,1-2.124-.8A3.42,3.42,0,0,1,68.3,41.1Zm.974,0a2.266,2.266,0,0,0,.708,1.682,2.214,2.214,0,1,0,0-3.186A1.5,1.5,0,0,0,69.274,41.1Z"
                transform="translate(-68.3 -37.557)" fill="#fff"/>
          <path d="M79.4,44.061V37.6l4.426,4.6V37.954h.885v6.461l-4.426-4.6v4.249H79.4Z"
                transform="translate(-69.575 -37.511)" fill="#fff"/>
          <path d="M90.574,38v5.222h1.77v.885H89.6V38Z" transform="translate(-70.747 -37.557)" fill="#fff"/>
          <path d="M97.185,38v6.107H96.3V38Z" transform="translate(-71.517 -37.557)" fill="#fff"/>
          <path d="M101.5,44.061V37.6l4.426,4.6V37.954h.885v6.461l-4.426-4.6v4.249H101.5Z"
                transform="translate(-72.114 -37.511)" fill="#fff"/>
          <path d="M115.163,38.885h-2.478v1.5h2.39v.885h-2.39v2.036h2.478V44.2H111.8V38h3.363Z"
                transform="translate(-73.297 -37.557)" fill="#fff"/>
          <path d="M128.217,38.972l-.708.443c-.177-.266-.266-.443-.354-.443-.089-.089-.266-.089-.531-.089a1.279,1.279,0,0,0-.62.177.765.765,0,0,0-.266.531c0,.266.177.531.62.708l.62.266a2.022,2.022,0,0,1,1.062.708,1.411,1.411,0,0,1,.354,1.062,1.828,1.828,0,0,1-1.947,1.947,2.174,2.174,0,0,1-1.328-.443,2.326,2.326,0,0,1-.62-1.328l.974-.177a1.144,1.144,0,0,0,.177.708.863.863,0,0,0,.8.354.914.914,0,0,0,.974-.974.532.532,0,0,0-.089-.354c0-.089-.089-.177-.177-.266l-.266-.266a.671.671,0,0,0-.354-.177l-.62-.266a1.745,1.745,0,0,1-1.239-1.5,1.558,1.558,0,0,1,.531-1.151A1.738,1.738,0,0,1,126.536,38,1.552,1.552,0,0,1,128.217,38.972Z"
                transform="translate(-74.756 -37.556)" fill="#fff"/>
          <path d="M133.674,38v5.222h1.77v.885H132.7V38Z" transform="translate(-75.698 -37.557)" fill="#fff"/>
          <path d="M138.7,41.1a3.017,3.017,0,0,1,.974-2.213A3.073,3.073,0,0,1,141.886,38a3.13,3.13,0,0,1,2.213,5.4,3.135,3.135,0,0,1-2.3.885,3.172,3.172,0,0,1-2.124-.8A3.42,3.42,0,0,1,138.7,41.1Zm.885,0a2.266,2.266,0,0,0,.708,1.682,2.214,2.214,0,1,0,0-3.186A1.663,1.663,0,0,0,139.585,41.1Z"
                transform="translate(-76.387 -37.557)" fill="#fff"/>
          <path d="M151.1,38.885v5.222h-.885V38.885H148.8V38h3.717v.885Z" transform="translate(-77.548 -37.557)"
                fill="#fff"/>
          <path d="M164.144,40.755l1.062,1.328h0l.354-.354c.089-.089.177-.266.266-.354l.62.62c-.089.089-.177.266-.266.354-.089.177-.266.266-.443.443l1.151,1.416h-1.151l-.62-.708a2.3,2.3,0,0,1-1.77.885,2.17,2.17,0,0,1-1.416-.531,1.8,1.8,0,0,1-.531-1.328,1.643,1.643,0,0,1,.885-1.5l.531-.354h.089a1.825,1.825,0,0,1-.62-1.239,1.268,1.268,0,0,1,.443-.974,1.77,1.77,0,0,1,2.124,0,1.268,1.268,0,0,1,.443.974,1,1,0,0,1-.266.708C164.852,40.224,164.586,40.49,164.144,40.755Zm-.708.531h0a4.026,4.026,0,0,0-.8.62.65.65,0,0,0-.177.531,1.177,1.177,0,0,0,.266.708,1,1,0,0,0,.708.266,2.014,2.014,0,0,0,1.239-.708Zm.177-1.151h0a1.528,1.528,0,0,1,.354-.266l.177-.177c.089-.089.089-.177.089-.354a.46.46,0,0,0-.177-.354.676.676,0,0,0-.443-.177c-.177,0-.266.089-.443.177a.671.671,0,0,0-.177.354,1.258,1.258,0,0,0,.177.531l.443.266Z"
                transform="translate(-78.995 -37.569)" fill="#fff"/>
          <path d="M180.868,38.343V39.4a2.589,2.589,0,0,0-1.682-.708,2.271,2.271,0,0,0-2.213,2.39,2.122,2.122,0,0,0,2.213,2.213,1.767,1.767,0,0,0,.8-.177c.089-.089.266-.089.354-.177a1.554,1.554,0,0,0,.443-.354v1.151a3.661,3.661,0,0,1-1.682.443,3.025,3.025,0,0,1-3.1-3.1,2.8,2.8,0,0,1,.8-2.036,3.157,3.157,0,0,1,2.478-1.151A3.607,3.607,0,0,1,180.868,38.343Z"
                transform="translate(-80.672 -37.546)" fill="#fff"/>
          <path d="M189.049,42.657h-2.567l-.708,1.5H184.8l3.009-6.461,2.921,6.461h-.974Zm-.354-.885-.885-2.036-.974,2.036Z"
                transform="translate(-81.683 -37.523)" fill="#fff"/>
          <path d="M198.318,38.972l-.708.443c-.177-.266-.266-.443-.354-.443-.089-.089-.266-.089-.531-.089a1.279,1.279,0,0,0-.62.177.765.765,0,0,0-.266.531c0,.266.177.531.62.708l.62.266a2.022,2.022,0,0,1,1.062.708,1.411,1.411,0,0,1,.354,1.062,1.828,1.828,0,0,1-1.947,1.947,2.174,2.174,0,0,1-1.328-.443,2.326,2.326,0,0,1-.62-1.328l.974-.177a1.144,1.144,0,0,0,.177.708.863.863,0,0,0,.8.354.914.914,0,0,0,.974-.974.532.532,0,0,0-.089-.354c0-.089-.089-.177-.177-.266l-.266-.266a.671.671,0,0,0-.354-.177l-.62-.266a1.745,1.745,0,0,1-1.239-1.5,1.558,1.558,0,0,1,.531-1.151A1.738,1.738,0,0,1,196.636,38,1.679,1.679,0,0,1,198.318,38.972Z"
                transform="translate(-82.809 -37.556)" fill="#fff"/>
          <path d="M203.685,38v6.107H202.8V38Z" transform="translate(-83.751 -37.557)" fill="#fff"/>
          <path d="M208,44.061V37.6l4.426,4.6V37.954h.885v6.461l-4.426-4.6v4.249H208Z"
                transform="translate(-84.349 -37.511)" fill="#fff"/>
          <path d="M218,41.1a3.017,3.017,0,0,1,.974-2.213A3.073,3.073,0,0,1,221.186,38a3.13,3.13,0,0,1,2.213,5.4,3.135,3.135,0,0,1-2.3.885,3.172,3.172,0,0,1-2.124-.8A3.42,3.42,0,0,1,218,41.1Zm.885,0a2.266,2.266,0,0,0,.708,1.682,2.214,2.214,0,1,0,0-3.186A1.663,1.663,0,0,0,218.885,41.1Z"
                transform="translate(-85.497 -37.557)" fill="#fff"/>
          <path d="M237.452,40.921h2.478V41.1a5.225,5.225,0,0,1-.177,1.239,1.837,1.837,0,0,1-.531.885,2.827,2.827,0,0,1-2.124.974,3.1,3.1,0,1,1,0-6.2,3.552,3.552,0,0,1,1.328.266,3.748,3.748,0,0,1,1.151.974l-.62.62a2.24,2.24,0,0,0-4.072,1.416,2.266,2.266,0,0,0,.708,1.682,2.073,2.073,0,0,0,1.5.62,2.115,2.115,0,0,0,1.239-.443,1.889,1.889,0,0,0,.62-1.062h-1.5Z"
                transform="translate(-87.335 -37.557)" fill="#fff"/>
          <path d="M247.849,42.657h-2.567l-.708,1.5H243.6l3.009-6.461,2.921,6.461h-.974Zm-.443-.885-.885-2.036-.974,2.036Z"
                transform="translate(-88.438 -37.523)" fill="#fff"/>
          <path d="M253.4,44.05l1.239-6.55,2.124,4.691,2.124-4.691,1.151,6.55h-.974l-.62-3.718-1.77,3.983-1.77-3.983-.62,3.718Z"
                transform="translate(-89.564 -37.5)" fill="#fff"/>
          <path d="M268.363,38.885h-2.478v1.5h2.39v.885h-2.39v2.036h2.478V44.2H265V38h3.363Z"
                transform="translate(-90.897 -37.557)" fill="#fff"/>
        </g>
      </svg>
export default Logo;
