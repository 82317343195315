import React from "react";
import styled from "@emotion/styled";
import {breakpoints, color} from "../../utils/style";
import IcomoonIcon from '../shared/IcomoonIcon';

const showHideChat = (id, frameID) => {
  const chatBox = document.getElementById(id);
  let chatIframe = document.getElementById(frameID);
  chatBox.classList.toggle('open');

  if (chatBox.classList.contains('open') && (!chatIframe.src || chatIframe.src === 'about:blank')) {
    chatIframe.src = process.env.FRESHCHAT_IFRAME_SRC;
  }
  else {
    document.body.style.overflow = 'initial';
  }

};

const Wrap = styled('div')`
  .live-chat {
    bottom: 10px;
    position: fixed;
    right: 10px;
    background: ${color.yellow};
    padding: 5px 30px;
    font-size: 1rem;
    font-weight: bold;
    line-height: 35px;
    color: #000;
    cursor: pointer;
    outline:none;
    border:none;
    border-radius: 25px;
    text-transform: uppercase;
    svg {
      margin-right: 10px;
    }
  }
  
  .live-chat-text {
    padding: 5px 10px;
    background: ${color.white};
    margin-top:-12px;
    line-height: 2;
    font-size: 14px;
    font-style: italic;
  }
    
  .live-chat-popup {
    height: 880px;
    width: 420px;
    position:fixed;
    right:4px;
    bottom:0;
    z-index:999;
    overflow:hidden;
    display: none;
      
    iframe {
      position: absolute;
      bottom: 0;
      right: -5px;
      width: 1280px;
      height: 730px;
      border: 0;
    }
  }
    
  &.open {
    bottom: 0;
    .live-chat-popup {
      display: block;
    }
    .live-chat {
      display: none;
    }
  }
  
  .close-chat {
    z-index: 10;
    position: absolute;
    right: 30px;
    top: 2px;
    padding: 5px 12px;
    border: none;
    background-color: #ffffff;
    border-radius: 25px;
    outline: none;
    transition: .3s;
    display: none;
    
    &:hover {
      background-color: #d8d8d8;
      cursor: pointer;
      transition: .3s;
    }
  }
  
  @media (max-width: ${breakpoints.md}) {
    .live-chat {
      display: none;
    }
    .live-chat-popup {
      width: 100%;
      height: 100%;
      right: 0;
      
      iframe {
        right: 0px;
        left: 0;
        width: 100%;
        height: 100%;        
        background: #f7bc00;
      }
    }
    .close-chat {
      display: block;
      right: 11px;
      top: 16px;
      padding: 8px;
      border-radius: 5px;
    }
  }
  
`;

export default () => (
  <Wrap id="chat-wrapper">
    <button className="live-chat" id="live-chat" onClick={() => showHideChat('chat-wrapper', 'chat-frame')}>
      <IcomoonIcon icon="chat-2" color={color.black} size={16}/>
      <span>แชทสด</span>
    </button>
    <div className="live-chat-popup" id="live-chat-popup" >
      <button id="close-chat-frame-button" className="close-chat"
              onClick={() => showHideChat('chat-wrapper', 'chat-frame')}>
        <IcomoonIcon icon="close" color={color.trueBlack} size={18}/>
      </button>
      <iframe id="chat-frame" className="chat-frame" title="Freshchat" src="about:blank" />
    </div>
  </Wrap>
);