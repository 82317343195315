import React, {useContext} from 'react';
import styled from '@emotion/styled';
import { breakpoints, sizing, color, font } from '../../utils/style';
import ButtonLink from '../shared/ButtonLink';
import {LogoutButton} from '../Auth/Auth';
import StoreContext from "../shared/Context/StoreContext";

const HeaderContacts = styled('div')`
  text-align: right;
  padding-top: 8px;
  .header-search-wrapper {
    display: inline-block;
  }
  .header-contact-group {
    display: inline-block;
    vertical-align: top;
    .button {
      padding: 9px 23px 5px;
      margin: 0 25px;
      font-size: 1.1rem;
      text-align: left;
      display: inline-block;
      vertical-align: middle;
    }
    .green-button {
      background-color: rgb(3, 164, 3);
      float: right;
      margin: 0;
      span {
        font-size: ${sizing.default};
        font-weight: ${font.mediumWeight};
        display: inline-block;
        vertical-align: middle;
        text-transform: uppercase;
        color: ${color.white};
      }
      
      &:hover {
        background-color: rgb(0, 197, 0);
      }
    }
    .register-button {
      overflow: hidden;
      height: 47px;
      width: 120px;
      font-size: ${sizing.default};
      padding: 0;
      text-align: center;
      line-height: 17px;
      transition: none;
      span {
        display: block;
        height: 100%;
        line-height: 45px;
        background-image: linear-gradient(to right,#eacf88 0%,#b79139 50%,#eacf88 120%);
        background-size: 200% auto;
        transition: .5s;
      }
      &:hover, &:active {
        background-color: ${color.gold};
        span {
          transition: .5s;
          background-position: right center;
          color: ${color.lightBlack};
        }
      }
    }
    .logout-button {
      height: auto;
      padding: 14px 23px;
      width: 178px;
      text-align: center;
      svg {
        margin-left: 7px;
        vertical-align: top !important;
      }
    }
    
  }
  .header-image-wrap, .button {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 20px;
  }
  .header-image-wrap .gatsby-image-wrapper {
    border-radius: 100%;
  }
  .popup-content {
    text-align: center;
  }
  
  @media (max-width: ${breakpoints.lg}) {
    .header-contact-group {
      .button {
        margin: 0 5px;
      }
    }
  }
  
  @media (max-width: ${breakpoints.md}) {
    display: none;
    .header-contact-group {
      display: none;
    }
    .header-search-wrapper {
      display: block;
      width: 100%;
      position: relative;
      .close-menu {
        position: absolute;
        top: 22px;
        right: 5px;
        background: none;
      }
    }
  }
`;

export default ({ children }) => {
  const value = useContext(StoreContext);
  const username = value.username;
  return(
  <HeaderContacts id="header-contacts-menu" className="header-contacts-menu">
    {children}
    <div className="header-contact-group">
      <ButtonLink
        to={process.env.LINE_ID_EXT}
        text={process.env.LINE_ID_TEXT}
        external={true}
        className="button green-button"
        iconName="line"
        iconSize={30}
        iconColor={color.white}
        ariaText="Line"
        iconLeft={true}
      />
      {username ?
        <LogoutButton/> :
        <a className="button register-button"
              href="/register/?register_type=onsitegames" aria-label="สมัครสมาชิก">
          <span>สมัครสมาชิก</span>
        </a>
      }
    </div>
  </HeaderContacts>
)};
