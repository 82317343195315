import React from 'react';
import { color } from '../../../utils/style';
import IcomoonIcon from '../../shared/IcomoonIcon';

const showHideChat = (id, frameID) => {
  const chatBox = document.getElementById(id);
  let chatIframe = document.getElementById(frameID);
  chatBox.classList.toggle('open');

  if (chatBox.classList.contains('open')) {
    if (!chatIframe.src || chatIframe.src === 'about:blank') {
      chatIframe.src = process.env.FRESHCHAT_IFRAME_SRC_NO_BUTTON;
    }

    document.body.style.overflow = 'hidden';
  }
};

const MobileContactLinkFresh = () => {
  return (
    <button aria-label="Chat" onClick={() => showHideChat('chat-wrapper', 'chat-frame')} rel="noopener noreferrer">
      <IcomoonIcon className="contact-menu-icon" icon="chat-new" color={color.gold} size={30} />
    </button>
  );
};

export default MobileContactLinkFresh;