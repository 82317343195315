import React, { useState } from 'react';
import { Global, css } from '@emotion/core';
import styled from '@emotion/styled';
import { reset, typography, breakpoints, color } from '../../utils/style';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Breadcrumbs from './Breadcrumbs';
import PopupWindow from './PopupWindow';
import { getAuthUserName } from '../Auth/Utils';
import StoreContext from './Context/StoreContext.js'

const Content = styled('div')`
  display: flex;
  min-height: 100vh;
  flex-direction: column; 
  background-position: bottom right;
  &.inter-blocked, &.popup-blocked {
    position: relative;
    overflow: hidden;
    height: 100vh;
    
    #toFix {
      z-index: 0;
    }
  }
  &.bg-added {
    background: ${color.black};
    header {
     background: ${color.black};
    }
  }
  &.popup-blocked {
    .nav-wrap {
      position: static;
    }
    #app-header, .logo {
      z-index: 0;
    }
  }
  @media (max-width: ${breakpoints.md}) {
    position: relative;
  }
`;

const AllContent = styled('div')`
  flex-grow: 1;
`;

const Main = styled('main')`
  .with-overflow-slider { 
    overflow-x: hidden; 
  }
`;

export default ({ children, breadcrumbs, page_title }) => {
  const [username, setUsername] = useState(getAuthUserName());
  return([
    <Global key="reset" styles={css`${reset} ${typography}`}/>,
    <StoreContext.Provider key="login-context-provider" value={{username: username, setUsernameCallback: setUsername}}>
      <Content key="app-content" id="all-content">
        <AllContent key="app-content-block">
          <Header key="app-header" id="app-header" location="/"/>
          <Main key="app-content-main">
            {page_title ? <Breadcrumbs breadcrumbs={breadcrumbs} page_title={page_title}/> : ''}
            {children}
          </Main>
        </AllContent>
        <Footer key="app-footer"/>
        <PopupWindow key="popup-window" title="Popup window" />
      </Content>
    </StoreContext.Provider>
  ])}