import { css } from '@emotion/core';

export const breakpoints = {
  xl: '1580px',
  lg: '1280px',
  md: '960px',
  mdMin: '961px',
  sm: '600px',
  smMin: '601px',
  xs: '480px',
  xxs: '350px'
};

export const color = {
  black: '#1f1b1e',
  transparentWhite1: 'rgba(255,255,255,0.1)',
  transparentWhite2: 'rgba(255,255,255,0.2)',
  transparentWhite7: 'rgba(255,255,255,0.7)',
  transparentWhite8: 'rgba(255,255,255,0.8)',
  transparent: 'transparent',
  midGrey: '#c4c4c4',
  textGrey: '#BBB',
  formGrey: '#2b2a2d',
  inputBg: 'rgba(255, 255, 255, 0.05)',
  inputBgFocus: 'rgba(255, 255, 255, 0.1)',
  labelColor: 'rgba(255, 255, 255, 0.5)',
  yellow: '#ffc874',
  gradientYellow: '#ce9a4b',
  /******* New styles *******/
  gold: '#ecc45a',
  darkGold: '#b79139',
  lightBrown: '#cc8a11',
  darkBrown: '#82530b',
  lightBlack: '#2b2d25',
  grey: '#c4c4c4',
  darkGrey: '#46463D',
  orange: '#ff4500',
  orange2: '#FF872C',
  white: '#ffffff',
  trueBlack: '#000000',
  stepBgGrey: '#4f5350',
  dotsSlider: '#eebe22',
  stars: 'rgb(255,231,66)',
};

export const sizing = {
  default: '16px',
  text: '14px',
  small: '12px',
  large: '18px',
  huge: '44px',
  maxWidth: '1200px',
  width: '100%',
  /******* New styles *******/
  huger: '52px',
  lager: '20px',
  largest: '43px',
  buttonText: '15px',
  titleText: '22px',
  readMore: '14.3px',
};

export const font = {
  default: [
    'Kanit',
    'Arial',
    'sans-serif'
  ].join(),
  heading: [
    'Kanit',
    'Arial',
    'sans-serif'
  ].join(),
  normalWeight: 400,
  middleWeight: 500,
  mediumWeight: 600,
  boldWeight: 700,
  headingWeight: 800,
  lineHeight: 1.25,
};

export const button = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  color: ${color.lightBlack};
  background: ${color.gold};
  font-family: ${font.heading};
  font-size: ${sizing.buttonText};
  font-weight: ${font.normalWeight};
  letter-spacing: normal;
  line-height: 1;
  padding: 3px 23px;
  border: none;
  border-radius: 28px;
  padding: 13px 23px;
  border-radius: 25px;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: none;
  span {
    color: ${color.lightBlack};
  }

  &:hover {
    cursor: pointer;
    outline: none;
    background-color: ${color.darkGold};
    text-decoration: none;
  }

  &:hover,
  &:active,
  &:focus {
    outline: none;
    text-decoration: none;
  }

  &.gold-button {
    text-transform: uppercase;
    svg {
      path {
        fill: ${color.lightBlack} !important;
      }
    }
    span {
      color: ${color.lightBlack};
    }
  }
  &.transparent-button {
    border-radius: 24px;
    border: solid 1px ${color.gold};
    background-color: transparent;
    color: ${color.white};
    font-size: ${font.default};
    line-height: .88;
    svg {
      path {
        fill: ${color.white} !important;
      }
    }
    span {
      color: ${color.white};
    }
  }
  &.transparent-button-gold {
    border-radius: 20px;
    border: solid 1px ${color.gold};
    background-color: transparent;
    color: ${color.gold};
    font-size: ${font.readMore};
    font-weight: ${font.mediumWeight};
    svg {
      path {
        fill: ${color.gold} !important;
      }
    }
    span {
      color: ${color.gold};
    }
  }
  &.transparent-button-gold:hover, &.transparent-button-gold:active, &.transparent-button-gold:focus {
    background: ${color.gold};
    border: none;
    color: ${color.formGrey};
    span {
      color: ${color.formGrey};
    }
  }
  
  &.semi-transparent-button {
    background: ${color.transparentWhite2};
    border: none;
    color: ${color.white};
    span {
      color: ${color.white};
    }
  }
  &.semi-transparent-button:hover, &.semi-transparent-button:active, &.semi-transparent-button:focus {
    background: ${color.transparentWhite1};
    border: none;
    span {
      color: ${color.white};
    }
  }
  
  
 
  &.gradient-button {
    background-image: linear-gradient(to right, #eacf88 0%,#b79139 50%, #eacf88 120%);
    background-size: 200% auto;
    transition: 0.5s;
    color: ${color.trueBlack};
    font-weight: ${font.middleWeight};
    span {
      color: ${color.trueBlack};
    }
  }

  &.gradient-button:hover, &.gradient-button:active, &.gradient-button:focus {
    background-position: right center; 
  }
  &.no-text {
    padding: 12px;
    svg {margin: 0;}
  }
  
  &.grey-button {
    background-color: ${color.darkGrey};
    font-size: ${font.default};
    line-height: .88;
    color: ${color.white};
    svg {
      path {
        fill: ${color.white} !important;
      }
    }
    span {
      color: ${color.white};
    }
  }
  &.grey-button-weight {
    background-color: ${color.darkGrey};
    font-weight: ${font.mediumWeight};
    font-size: ${font.default};
    color: ${color.white};
    svg {
      path {
        fill: ${color.white} !important;
      }
    }
    span {
      color: ${color.white};
    }
  }
`;

export const formStyles = css`
  h2 {
    margin: 0 0 2rem;
    color:${color.white};
  }
  .button {
    margin-right:15px;
    border:none;
    ${button}
  }
  .button:disabled {
    background-image: none !important;
    background-color: ${color.midGrey};
    border:1px solid ${color.midGrey};
    cursor:not-allowed;
    color: ${color.lightBlack};
    background-image: none;
    span {
      color: ${color.lightBlack} !important;
    }
    &:hover, &:active, &:focus {
      background-image: none !important;
      background-color: ${color.midGrey};
      border:1px solid ${color.midGrey};
      color: ${color.lightBlack} !important;
    }
  }
  .fieldWrap {
    margin-bottom:5px;
    padding-bottom:20px;
    position:relative;
    .error {
      position: absolute;
      left: 0;
      bottom: 3px;
      font-size: 12px;
      padding: 0 20px;
      color: ${color.labelColor};
      color:${color.red};
      max-width:50%;
    }
    .input-notice {
      position: absolute;
      left: 0;
      bottom: 3px;
      font-size: 12px;
      padding: 0 20px;
      color: ${color.labelColor};
    }
    .error-new {
      position: absolute;
      right: 4px;
      top: -18px;
      font-size: 12px;
      padding: 0 5px;
      color:${color.red};
      background: none;
    }
    .error-new + input, .error-new + select, .error-new + textarea {
      border-color: ${color.red};
    }
  }
  
  .with-label .error {
    top: 37px;
  }
  
  .checkboxGroupWrap {
    position: relative;
    p {
      margin-bottom: 10px;
    }
    .minimal-custom-checkbox {
      margin-bottom: 0;
    }
  }
  
  .checkboxGroupWrap .error, .radioGroupWrap .error {
    position: absolute;
    right: 0;
    top: 5px;
    text-align: right;
    background: none;
    font-size:12px;
    color:${color.red};
  }
  label {
    padding:0 20px 5px;
    display: block;
    cursor: pointer;
    color: ${color.labelColor};
    font-size: 12px;
  }
  .iconLabel {
    svg {
      vertical-align: baseline !important;
      margin-right: 5px;
    }
  }
  input[type=text], input[type=email], 
  input[type=password], textarea {
    background:${color.inputBg} !important;
    color:${color.white} !important;
    line-height:1.5;
    font-size:16px;
    padding:10px 20px;
    width:100%;
    border:1px solid transparent;
    border-radius: 24px;
    font-family: inherit;
    &.white-input {
      background:${color.white} !important;
      color:${color.midGrey} !important;
      border-color: ${color.white};
    }
  }
  input:focus, textarea:focus, select:focus {
    outline:none;
    border-color:${color.white};
    background:${color.inputBgFocus} !important;
    &.white-input {
      border-color:${color.orange};
      background:${color.orange} !important;
      color: ${color.black};
    }
  }
  input + span, textarea + span {
    color: ${color.red};
    font-size:12px;
    display:block;
    padding:5px 5px 0;
  }
  textarea {
    min-height:7rem;
    width:100%;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active  {
     box-shadow: 0 0 0 30px ${color.black} inset !important;;
     -webkit-text-fill-color: ${color.white} !important;
  }
  select {
   display: block;
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   background:${color.inputBg} !important;
   color:${color.white} !important;
   line-height:1.5;
   font-size:16px;
   padding:10px 20px;
   width:100%;
   border:1px solid ${color.inputBg};
   border-radius: 24px;
   font-family: inherit;
   option {
     color: ${color.black} !important;
   }
  }
  select::-ms-expand {
    display: none;
  }
  .iconField {
    position: relative;
    input {
      padding-right: 48px;
    }
    svg, .icon {
      position: absolute;
      right: 16px;
      top: 14px;
      opacity: 0.5;
      pointer-events: none;
    }
  }
  
  .minimal-custom-radio {
    .radio-wrap {
      margin-bottom: 10px;
    }
    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
    }
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      color: #666;
    }
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border: 1px solid ${color.textGrey};
      border-radius: 100%;
      background: ${color.black};
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
      content: '';
      width: 12px;
      height: 12px;
      background: ${color.yellow};
      position: absolute;
      top: 3px;
      left: 3px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }
    [type="radio"]:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    [type="radio"]:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  .minimal-custom-checkbox {
    margin-bottom: 10px;
    input {
      position: absolute;
      opacity: 0;
    }

    input + label {
      position: relative;
      cursor: pointer;
      padding: 0;
      font-size: 14px;
    }

    input + label:before {
      content: '';
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-bottom;
      width: 20px;
      height: 20px;
      background: ${color.black};
      border: 1px solid ${color.white};
      border-radius: 2px;
    }

    input:hover + label:before {
      background: ${color.formGrey};
    }
  
    input:focus + label:before {
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }
  
    input:disabled + label {
      color: ${color.white};
      cursor: auto;
    }

    input:disabled + label:before {
      box-shadow: none;
      background: ${color.formGrey};
    }
    input:checked + label:after {
      content: '';
      position: absolute;
      left: 5px;
      top: 9px;
      background: white;
      width: 2px;
      height: 2px;
      box-shadow: 
        2px 0 0 white,
        4px 0 0 white,
        4px -2px 0 white,
        4px -4px 0 white,
        4px -6px 0 white,
        4px -8px 0 white;
      transform: rotate(45deg);
    }
  }
  .form-bottom {
    .button {
      float: right;
    }
    .notice {
      font-size: 12px;
      color: ${color.transparentWhite2};
    }
  }
  @media (max-width: ${breakpoints.md}) {
    h2 {
      margin-top:2rem;
    }
    input[type=text], input[type=email], 
    input[type=password] {
      width:100%;
    }
  }
  
`;

export const reset = css`
    /* thai */
    @font-face {
      font-family: 'Kanit';
      font-style: normal;
      font-weight: 400;
      src: url(https://fonts.gstatic.com/s/kanit/v7/nKKZ-Go6G5tXcraBGwCYdA.woff2) format('woff2');
      unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
      font-display: swap;
    }
    /* latin-ext */
    @font-face {
      font-family: 'Kanit';
      font-style: normal;
      font-weight: 400;
      src: url(https://fonts.gstatic.com/s/kanit/v7/nKKZ-Go6G5tXcrabGwCYdA.woff2) format('woff2');
      unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      font-display: swap;
    }
    /* latin */
    @font-face {
      font-family: 'Kanit';
      font-style: normal;
      font-weight: 400;
      src: url(https://fonts.gstatic.com/s/kanit/v7/nKKZ-Go6G5tXcraVGwA.woff2) format('woff2');
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      font-display: swap;
    }
    /* thai */
    @font-face {
      font-family: 'Kanit';
      font-style: normal;
      font-weight: 500;
      src: url(https://fonts.gstatic.com/s/kanit/v7/nKKU-Go6G5tXcr5mOBWzVaF5NQ.woff2) format('woff2');
      unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
      font-display: swap;
    }
    /* latin-ext */
    @font-face {
      font-family: 'Kanit';
      font-style: normal;
      font-weight: 500;
      src: url(https://fonts.gstatic.com/s/kanit/v7/nKKU-Go6G5tXcr5mOBWpVaF5NQ.woff2) format('woff2');
      unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      font-display: swap;
    }
    /* latin */
    @font-face {
      font-family: 'Kanit';
      font-style: normal;
      font-weight: 500;
      src: url(https://fonts.gstatic.com/s/kanit/v7/nKKU-Go6G5tXcr5mOBWnVaE.woff2) format('woff2');
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      font-display: swap;
    }
    /* thai */
    @font-face {
      font-family: 'Kanit';
      font-style: normal;
      font-weight: 600;
      src: url(https://fonts.gstatic.com/s/kanit/v7/nKKU-Go6G5tXcr5KPxWzVaF5NQ.woff2) format('woff2');
      unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
      font-display: swap;
    }
    /* latin-ext */
    @font-face {
      font-family: 'Kanit';
      font-style: normal;
      font-weight: 600;
      src: url(https://fonts.gstatic.com/s/kanit/v7/nKKU-Go6G5tXcr5KPxWpVaF5NQ.woff2) format('woff2');
      unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      font-display: swap;
    }
    /* latin */
    @font-face {
      font-family: 'Kanit';
      font-style: normal;
      font-weight: 600;
      src: url(https://fonts.gstatic.com/s/kanit/v7/nKKU-Go6G5tXcr5KPxWnVaE.woff2) format('woff2');
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      font-display: swap;
    }
    /* thai */
    @font-face {
      font-family: 'Kanit';
      font-style: normal;
      font-weight: 700;
      src: url(https://fonts.gstatic.com/s/kanit/v7/nKKU-Go6G5tXcr4uPhWzVaF5NQ.woff2) format('woff2');
      unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
      font-display: swap;
    }
    /* latin-ext */
    @font-face {
      font-family: 'Kanit';
      font-style: normal;
      font-weight: 700;
      src: url(https://fonts.gstatic.com/s/kanit/v7/nKKU-Go6G5tXcr4uPhWpVaF5NQ.woff2) format('woff2');
      unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      font-display: swap;
    }
    /* latin */
    @font-face {
      font-family: 'Kanit';
      font-style: normal;
      font-weight: 700;
      src: url(https://fonts.gstatic.com/s/kanit/v7/nKKU-Go6G5tXcr4uPhWnVaE.woff2) format('woff2');
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      font-display: swap;
    }
    /* thai */
    @font-face {
      font-family: 'Kanit';
      font-style: normal;
      font-weight: 800;
      src: url(https://fonts.gstatic.com/s/kanit/v7/nKKU-Go6G5tXcr4yPRWzVaF5NQ.woff2) format('woff2');
      unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
      font-display: swap;
    }
    /* latin-ext */
    @font-face {
      font-family: 'Kanit';
      font-style: normal;
      font-weight: 800;
      src: url(https://fonts.gstatic.com/s/kanit/v7/nKKU-Go6G5tXcr4yPRWpVaF5NQ.woff2) format('woff2');
      unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      font-display: swap;
    }
    /* latin */
    @font-face {
      font-family: 'Kanit';
      font-style: normal;
      font-weight: 800;
      src: url(https://fonts.gstatic.com/s/kanit/v7/nKKU-Go6G5tXcr4yPRWnVaE.woff2) format('woff2');
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      font-display: swap;
    }
    html,
    body,
    *,
    *::before,
    *::after {
      box-sizing: border-box;
      margin: 0;
    }
    img {
      display: inline-block;
      max-width: 100%;
      height: auto;
    }
    html, body {
      height: 100%;
    }
    body {
      background: #000000 radial-gradient(circle at 48% -12%, rgba(255, 200, 116, 0.19), rgba(255, 200, 116, 0) 47%) no-repeat fixed center;
      color: ${color.white};
      margin: 0;
      font-family: ${font.heading};
      scroll-behavior: smooth;
    }
    
    a, button {
      * {
        pointer-events: none;
      }
    }
    
    svg {
      pointer-events: none;
    }
 
    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-track {
      background: rgba(255,255,255,0.2);
      border-radius: 2px;
    }

    ::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 2px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: ${color.white}; 
    }
    ::placeholder { 
      color: ${color.white};
      opacity: 0.4;
    }

    :-ms-input-placeholder {
      color: ${color.white};
      opacity: 0.4;
    }
    ::-ms-input-placeholder {
      color: ${color.white};
      opacity: 0.4;
    }
    
    input:focus::placeholder {
      color: transparent;
    }
    
    @media screen and (prefers-reduced-motion: reduce) {
	    body {
		    scroll-behavior: auto;
	    }
    }
  `;

export const wrapper1200 = css`
    max-width: 1254px;
    margin: 0 auto;
    padding: 0 15px;
    @media (max-width: ${breakpoints.md}) {
      padding: 0 15px;
    }
`;

export const wrapper = css`
    margin: 0 auto;
    max-width: 1264px;
    padding: 0 20px;
`;

export const featuredWrapper = css`
    position: relative;
`;

export const noFeaturedWrapper = css`
    position: relative;
`;

export const typography = css`
  h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  h5,
  .h5,
  h6 {
    margin-top: 30px;
    color: ${color.orange};
    font-family: ${font.heading};
    font-weight: ${font.headingWeight};
    font-size: 22px;
    em,
    i,
    strong,
    b {
      font-weight: inherit;
    }

    &.rich-design {
      span {
        color: ${color.gold};
      }
    }
    &.big-header {
      font-size: 70px;
    }
  }
  h1, .h1 {
    color: ${color.gold};
    font-size: ${sizing.largest};
    font-weight: ${font.headingWeight};
    &.rich-design {
      span {
        color: ${color.gold};
      }
    }
  }
  h1 + div {
    margin-top: 1.5rem;
  }
  h2,
  .h2 {
    color: ${color.gold};
    font-size: ${font.lagrest};
    font-weight: ${font.headingWeight};
  }
  h3,
  .h3 {
    color: ${color.gold};
    font-size: ${font.titleText};
    font-weight: ${font.boldWeight};
    &.with-icon {
      color: ${color.gold};
      display: flex;
      align-items: center;
      svg {
        margin-right: 15px;
      }
    }
  }
  h4, .h4 {
    color: ${color.white};
    font-size: ${font.lager};
    font-weight: ${font.mediumWeight};
  }
  h5,
  .h5,
  h6 {
    font-size: 1.2rem;
    margin: 1.5rem 0 1rem;
    + * {
      margin-top: 0.5rem;
    }
  }
  .subheader {
    color: ${color.white};
    font-size: 22px;
    font-weight: ${font.mediumWeight};
    letter-spacing: 0.75;
    text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.32);
  }
  a {
    color: ${color.white};
    text-decoration: none;
    &:hover,
    &:active,
    &:focus {
      outline: none;
      text-decoration: underline;
      color: ${color.gold};
    }
  }
  ul {
    padding: 0;
  }
  blockquote {
    padding: 30px 40px;
    background-color: ${color.orange};
    font-weight: ${font.headingWeight};
    font-size: 1.8rem;
    border-radius: 24px;
    margin: 20px 0;
  }
  .button {
    ${button}
  }
  .screen-reader-text {
    clip: rect(0 0 0 0);
    overflow: hidden;
    position: absolute;
    height: 1px;
    width: 1px;
  }
  .white {
    color: ${color.white};
  }
  .orange {
    color: ${color.orange};
  }
  .a-center {
    text-align: center;
  }
  .a-left {
    text-align: left;
    h2 {
      text-transform: uppercase;
    }
  }
  .a-right {
    text-align: right;
  }
  .white-stroke {
    border: 1px solid ${color.white};
  }
  .opacity-04 {
    opacity: 0.4;
  }
  .stroked {
    -webkit-text-stroke: 1px ${color.white};
    -webkit-text-fill-color: ${color.transparent};
  }
  .no-padding {
    padding: 0 !important;
  }
  .table-wrapper-borders {
    margin: 20px 0;
    border: 1px solid ${color.transparentWhite1};
    border-radius: 10px;
  }
  table {
    margin: 0;
    width: 100%;
    border: 0;
    border-collapse: collapse;
    border: 1px solid ${color.transparentWhite1};
    border-radius: 10px;
    border-bottom: none;
    overflow: hidden;
    td,
    th {
      text-align: left;
      padding: 15px 20px;
    }
    thead {
      background: ${color.gold};
      color: ${color.lightBlack};
    }
    th {
      font-weight: ${font.headingWeight};
    }
    td {
      border-top: 1px solid ${color.transparentWhite1};
      font-weight: ${font.normalWeight};
    }
  }

  .css-col-2 {
    column-count: 2;
    column-gap: 30px;
  }

  .css-col-3 {
    column-count: 3;
    column-gap: 30px;
  }

  .flex {
    &::before {
      display: none !important;
    }
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    .col-sm-6 {
      -webkit-box-flex: 1;
      flex: 1 1 0;
      padding: 10px;
    }
    .col-4 {
      flex-basis: 24%;
      padding: 5px;
    }
    .col-3,
    .col-md-3,
    .col-md-4,
    .col-md-5 {
      flex-basis: 33%;
      padding: 10px;
      img {
        width: 100%;
      }
    }
    .col-2 {
      flex-basis: 49%;
      padding: 10px 20px;
    }
    .col-img {
      flex-basis: 30%;
    }
    .col-text {
      flex-basis: 67%;
    }
  }

  .iframe-container {
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;
    iframe {
      border: 0;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
  }

  .clear {
    clear: both;
  }

  .accordion {
    margin: 10px 0;
    background: ${color.transparentWhite2};
    border-radius: 10px;
    text-align: left;
    &.no-margin {
      margin: 0;
    }
    &.accordion-open {
      .accordion-content {
        height: auto;
        display: block;
      }
      .accordion-title {
        color: ${color.orange2} !important;
        &::after {
          top: 20px;
          border: solid ${color.orange2};
          border-width: 2px 0 0 2px;
        }
      }
    }
  }
  .accordion-title {
    position: relative;
    font-size: 16px;
    color: ${color.white} !important;
    line-height: 20px;
    padding: 15px 50px 15px 25px;
    cursor: pointer;
    margin: 0;
    &.half-width {
      max-width: 50%;
    }
    &::after {
      content: '';
      border: solid ${color.white};
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 6px;
      font-size: 20px;
      position: absolute;
      right: 20px;
      top: 15px;
      transform: rotate(45deg);
    }
    &:hover,
    &:active {
      color: ${color.orange2} !important;
      &::after {
        border-color: ${color.orange2};
      }
    }
  }
  .accordion-content {
    height: 0;
    display: none;
    padding: 0 25px 15px;
    transition: all 0.5s ease-in-out;
  }

  .tooltip {
    position: relative;
    display: inline-block;
    margin: 0 10px;
    cursor: pointer;
    .tooltip-title {
      display: inline-block;
      width: 18px;
      height: 18px;
      text-align: center;
      padding: 1px;
      border: 2px solid ${color.transparentWhite2};
      font-size: 12px;
      line-height: 1;
      border-radius: 10px;
      color: ${color.transparentWhite2};
    }
    .tooltip-content {
      position: absolute;
      bottom: 30px;
      left: 50%;
      width: 150px;
      margin-left: -75px;
      font-size: 12px;
      line-height: 1.1;
      background: ${color.grey};
      padding: 15px;
      border-radius: 10px;
      transition: all 1s linear;
      display: none;
      opacity: 0;
      &::after {
        content: '';
        border: 8px solid transparent;
        border-top-color: ${color.grey};
        position: absolute;
        bottom: -15px;
        left: 68px;
      }
    }
    .tooltip-content-left {
      left: auto;
      right: -9px;
      &::after {
        left: auto;
        right: 10px;
      }
    }
    .tooltip-content-right {
      left: -9px;
      &::after {
        left: 10px;
      }
    }
    &:hover,
    &:active {
      overflow: visible;
      .tooltip-title {
        color: ${color.transparentWhite8};
        border-color: ${color.transparentWhite8};
      }
      .tooltip-content {
        display: block;
        opacity: 1;
        color: ${color.transparentWhite8};
      }
    }
  }

  @media (max-width: ${breakpoints.md}) {
    h1,
    .h1 {
      font-size: 1.5rem;
      &.big-header {
        font-size: 28px;
      }
    }
    h2,
    .h2,
    h3,
    .h3 {
      font-size: 18px;
      line-height: 20px;
      margin-bottom: 15px;
      font-weight: ${font.mediumWeight};
    }

    h4,
    .h4,
    h5,
    .h5 {
      font-size: 1rem;
      line-height: 20px;
      font-weight: ${font.mediumWeight};
    }

    .subheader {
      font-size: 1rem;
    }
    blockquote {
      padding: 25px 7%;
      font-size: 1.2rem;
    }
    p {
      font-size: 14px;
    }
    table {
      td,
      th {
        padding: 10px 15px 10px 0;
        text-align: center;
      }
    }
    .flex {
      .col-img {
        text-align: center;
      }
      .col-4 {
        flex-basis: 49%;
      }
    }
    .css-col-2,
    .css-col-3 {
      column-count: 1;
    }
    .tooltip {
      .mobile-left {
        left: auto;
        right: 0;
        &::after {
          left: auto;
          right: 5px;
        }
      }
      .tooltip-content-left {
       left: auto;
       right: -2px;
        &::after {
          left: auto;
          right: 5px;
        }
      }
      .tooltip-content-right {
        left: -2px;
        &::after {
          left: 5px;
        }
      }
    }
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .flex {
      .col-3 {
        flex-basis: 31%;
      }
    }
    .flex {
      .col-2 {
        flex-basis: 48%;
      }
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    .flex {
      .col-2,
      .col-3 {
        flex-basis: 100%;
      }
    }
    .col-sm-6,
    .col-3,
    .col-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-img,
    .col-text {
      -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
      padding: 0;
    }
    .h3,
    h3,
    h2,
    .h2 {
      &.gradient,
      &.gradient-dark {
        span {
          padding: 3px 15px;
          font-size: 1.2rem;
          max-width: 70%;
        }
      }
    }
  }

  @media (max-width: ${breakpoints.xs}) {
    .accordion-title {
      font-size: 1rem;
      &.half-width {
        max-width: 100%;
      }
    }
  }
`;

export const Slick = css`
  .slick-slider {
    position: relative;
    z-index: 0;
    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}

.slick-loading .slick-list {
  background: ${color.black};
}

.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 48px;
  height: 48px;
  z-index: 10;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: ${color.white};
  outline: none;
  background: transparent;
  opacity: 0.7;
  border: none;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus,
.slick-next:active,
.slick-next:active
 {
  color: transparent;
  outline: none;
  background: transparent;
  opacity: 1;
}
.slick-prev.slick-disabled
.slick-next.slick-disabled {
  opacity: .25;
}


.slick-prev {
  left: 25px;
}
[dir='rtl'] .slick-prev {
  right: 25px;
  left: auto;
}


.slick-next {
  right: 25px;
}
[dir='rtl'] .slick-next {
  right: auto;
  left: 25px;
}


.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: 20px;
  width: 100%;
  margin: 0;
  list-style: none;
  text-align: center;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 11px;
  padding: 0;
  cursor: pointer;
  &:last-child {
    margin-right: 0;
  }
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 12px;
  height: 12px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: 0;
  border-radius: 50%;
  outline: none;
  background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 12px;
  height: 12px;
  content: '';
  text-align: center;
  background: ${color.gold} linear-gradient(to right, #ffc874 19%, #ce9a4b);
  border-radius: 50%;
  opacity: .25;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  background-color: ${color.dotsSlider};
  opacity: 1;
}

@media (max-width: ${breakpoints.sm}) {
  .slick-dots {
    display: block;
    bottom: -25px;
    padding-top: 5px;
    li {
      height: 8px;
      width: 8px;
      button {
        height: 8px;
        width: 8px;
        &:before {
          height: 8px;
          width: 8px;
        }
      }
    }
  }  
}
`;

export const NavUlStyles = css`
  .mobile-only { display: none; }
  @media (max-width: ${breakpoints.md}) {
    .mobile-only { 
      display: flex;
      padding: 20px 15px;
      justify-content: space-between;
      .button {
        flex-basis: 48%;
        padding: 13px;
      }
      .header-promo-link {
        background: ${color.transparentWhite1};
        border: none;
        display: flex;
        svg {
          display: inline-block;
          vertical-align: middle;
          margin-right: 10px;
        }
        span {
          color: ${color.gold};
        }
      }
    }
    
    &.open {
      -webkit-animation: slide-top 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) alternate both;
	        animation: slide-top 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) alternate both;
      display: block;
      position: fixed;
      bottom: 48px;
      left: 0;
      width: 100%;
      height: calc(100% - 113px);
      padding: 0;
      border-top: 10px solid ${color.black};
      background: ${color.black};
      margin: 0;
      .top-nav-ul {
        display: block;
        max-height: 90%;
        overflow-y: auto;
        padding-right: 0;
      }
      .menu-item {
        margin: 0;
        position: relative;
        display: block;
      }
      .top-menu-link {
        position: relative;
        margin: 0;
        height: auto;
        padding: 14px 20px 0 65px !important;
        border-left: none;
        text-align: left;
        font-weight: ${font.mediumWeight};
        span {
          display: block;
          padding-bottom: 14px;
          border-bottom: 1px solid ${color.transparentWhite1};
        }
        svg {
          position: absolute;
          left: 14px;
          top: 5px;
          width: 44px;
          height: auto;
          padding: 12px;
          background: ${color.transparentWhite1};
          border-radius: 25px;
        }
      }
    }
  }
`;

export const NavStylesNew = css`
  max-width: 1224px;
  width: 100%;
  margin: 0 auto 12px;
  clear: both;
  font-weight: ${font.mediumWeight};
  list-style: none;
  text-align: center;
  font-size: 1rem;
  .menu-item {
    display: table-cell;
    text-align: left;
  }
  .menu-item:nth-of-type(1) {
    & > a {
      padding-left: 5px;
    }
  }
  .menu-item:nth-last-of-type(2) {
    & > a {
      text-align: right;
      padding-right: 5px;
    }
  }
  .top-menu-link {
    padding: 10px 2em;
    text-transform: uppercase;
    height: 45px;
    color: ${color.transparentWhite8};
    svg {
      margin-right: 10px;
    }
    &.active {
      color: ${color.lightBrown};
      text-shadow: 0 0 9px ${color.lightBrown};
      svg {
        path {fill: ${color.lightBrown} !important;}
        filter: drop-shadow(1px 1px 6px ${color.lightBrown});
      }
    }
    &:hover {
      color: ${color.gold};
      text-shadow: 0 0 6px ${color.lightBrown};
      svg {
        path {fill: ${color.lightBrown} !important;}
        filter: drop-shadow(1px 1px 6px ${color.lightBrown});
      }
    }
  }
  .right-line {
    position: relative;
    &::after {
      content: "|";
      color: ${color.transparentWhite2};
      position: absolute;
      right: 0;
      top: 12px;
    }
  }
  .home .top-menu-link {
    text-align: left;
    padding-left: 5px;
  }
  li {
    text-align: left;
    
    a {
      display: block;
      color: ${color.white};
      position: relative;
    }
    a:hover, a:active, .active {
      text-decoration:none;
      color: ${color.white}
    }
    span {
      display: inline-block;
      vertical-align: middle;
      font-weight: 500;
    }
  }
  .mobile-collapse-link {
    display: none;
  }
  
  @supports (-ms-ime-align:auto) {
    font-size: 1.125rem;
  }
 
  @media (max-width: ${breakpoints.lg}) {
    font-size: 0.9rem;
  }
  
  @media (max-width: ${breakpoints.md}) {
    display: none;
    a.active { color: #FFF; }
    
    .menu-item:nth-last-of-type(2) {
    & > a {
      text-align: left;
      padding-right: 20px;
    }
  }
    
    .mobile-collapse-link {
      display: block;
      width: 40px;
      height: 40px;
      position: absolute;
      left: 9px;
      top: 13px;
      transform: rotate(0.75turn);
      pointer-events: none;
      svg {
        position: absolute;
        left: 19px;
        top: 15px;
      }
    }
     
    .open-item {
      .mobile-collapse-link {
        transform: none;
        pointer-events: auto;
        top: 0;
        svg {
          left: 15px;
          top: 16px;
        }
      }
    }
    
    .with-icon {
      .mobile-collapse-link {opacity: 0;}
    }

    @keyframes slide-top {
      0% {
        -webkit-transform: translateY(100%);
            transform: translateY(100%);
      }
      100% {
        -webkit-transform: translateY(-1px);
            transform: translateY(-1px);
      }
    }
    
  }
`;

export const phoneInputStyles = css`
  .PhoneInput {
    position: relative;
  }
  
  .PhoneInputCountry {
    display: flex;
    align-items: center;
    margin-right: 10px;
    position: absolute;
    top: 0;
    left: 0;
    height: 45px;
    padding: 5px 35px 0 15px;
    width: 70px;
    &::after {
      content: '';
      height: 20px;
      width: 1px;
      border-right: 1px solid ${color.white};
      position: absolute;
      right: 7px;
      top: 13px;
      opacity: 0.25;
    }
  }

  .PhoneInputInput {
    padding-left: 70px;
  }

  .PhoneInputCountryIcon {
    display: inline-block;
    max-width: 100%;
    svg {
      display: none;
    }
  }

  .PhoneInputCountryIcon--border {
  }

  .PhoneInputCountryIconImg {
    width: 100%;
    height: 100%;
  }

  .PhoneInputInternationalIconPhone {
    opacity: 0.5;
  }

  .PhoneInputInternationalIconGlobe {
    display: none;
  }

  .PhoneInputCountrySelect {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    border: 0;
    opacity: 0;
    cursor: pointer;
  }

  .PhoneInputCountrySelect[disabled] {
    cursor: default;
  }

  .PhoneInputCountrySelectArrow {
    display: block;
    content: '';
    width: 8px;
    height: 8px;
    border: 2px solid transparent;
    border-top-color: ${color.white};
    border-right-color: ${color.white};
    transform: rotate(135deg);
    opacity: 0.8;
    position: absolute;
    right: 18px;
    top: 18px;
  }

  .PhoneInputCountrySelect:focus
    + .PhoneInputCountryIcon
    + .PhoneInputCountrySelectArrow {
    opacity: 1;
  }

  .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
  }

  .PhoneInputCountrySelect:focus
    + .PhoneInputCountryIcon
    .PhoneInputInternationalIconGlobe {
  }
`;

export const ContentStyles = css`
  .gatsby-image-wrapper {
    margin: 20px auto;
  }
  h2, h3, h4 {
    color: ${color.gold};
  }
  p {
    margin: 15px 0;
    letter-spacing: 0.75px;
  }
  a:not(.button) {
    color: ${color.gold};
  }
  ol {
    list-style: none;
    counter-reset: li;
    margin: 15px 0;
    padding: 0;
    & > li {
      counter-increment: li;
      margin-left: 20px;
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }
      &::before {
        content: counter(li);
        display: inline-block;
        width: 1em;
        margin-left: -1.5em;
        margin-right: 0.5em;
        text-align: right;
        direction: rtl;
        color: ${color.white};
      }
      ul {
        margin-top: 5px;
        li {
          margin-bottom: 5px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  ul {
    list-style: none;
    font-weight: 600;
    letter-spacing: 0.75px;
    line-height: 160%;
    font-size: 16px;
    li {
      position: relative;
      padding-left: 25px;
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }
      &::before {
        content: "•";
        position: absolute;
        top: 0;
        left: 0;
        width: 1em;
        color: ${color.white};
        font-size: 1rem;
      }
    }
  }
  
  .yellow {
    color: ${color.gold};
  }
  
  .platform-buttons {
    margin: 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: start;
    margin: 0 -1%;
    .platform-button-link {
      display: block;
      margin: 0 1%;
      width: 23%;
      color: ${color.white};
      background: ${color.transparentWhite2};
      border-radius: 10px;
      padding: 20px 30px;
      opacity: 0.8;
      text-align: center;
      svg {
        max-width: 40%;
        max-height: 60px;
        display: inline-block;
        vertical-align: middle;
      }
      span {
        display: inline-block;
        vertical-align: middle;
        text-transform: uppercase;
        max-width: 50%;
      }
      &:hover, &:active {
        opacity: 1;
        text-shadow: 0 0 9px ${color.lightBrown};
        text-decoration: none;
        svg {
          filter: drop-shadow(1px 1px 6px ${color.lightBrown});
        }
      }
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    .platform-buttons {
      .platform-button-link {
        width: 48%;
        padding: 20px 5px;
        margin: 5px 1%;
      }
    }
  }
`;

export const ContentStylesExt = css`
  .list-game {
    .casion-box {
      width: 49%;
      float: left;
      margin-right: 2%;
      &:nth-of-type(2n+0) {
        margin-right: 0;
      }
    }
    & + div {
      clear: both;    
    }
  }
    
  .casion-box {
    border: 1px solid ${color.transparentWhite1};
    border-radius: 16px;
    padding: 16px;
    margin-bottom: 20px;
    h2 {
      margin-top: 0;
      font-size: 1.5rem;
    }
    &.gclub-bg-02 {
      display: flex;
      div {
        flex-basis: 33%;
        padding: 0 10px;
      }
      h3 {
        flex-basis: 100%;
      }
      p + img {
        max-width: 50%;
      }
      .register-game {
        flex-grow: 2;
      }
     
    }
  }
  
  .flex {
    .yellow-box {
      h2 {
        font-size: 1.2rem;
      }
      flex-basis: 31%;
      padding: 10px 15px;
      margin-bottom: 10px;
      border: 1px solid ${color.yellow};
      background: ${color.black};
      color: ${color.yellow};
    }
  }

  
  @media (max-width: ${breakpoints.sm}) {
    .list-game {
      .casion-box {
        width:100%;
        float: none;
        margin-right: 0;
      }
    }
    .casion-box {
      &.gclub-bg-02 {
        display: block;
        p + img {
          max-width: 100%;
        }
      }
    }
    .flex {
      .yellow-box {
        flex-basis: 100%;
      }
    }
  }
`;

export const arrowBtn = css`
  content: "";
  position: absolute;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 4px;
  vertical-align: middle;
`;
